<template>
  <svg
      class="netolnew-logo"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 770 123"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1,0,0,1,-253.019,-345.222)">
      <path d="M258.505,446.654C256.723,446.654 255.363,446.162 254.425,445.177C253.488,444.192 253.019,442.762 253.019,440.886L253.019,351.412C253.019,349.537 253.488,348.083 254.425,347.051C255.363,346.02 256.583,345.504 258.083,345.504C259.584,345.504 260.686,345.762 261.389,346.278C262.093,346.793 262.913,347.708 263.851,349.021L325.189,430.757L321.812,430.757L321.812,351.131C321.812,349.349 322.281,347.966 323.219,346.981C324.157,345.996 325.517,345.504 327.299,345.504C328.987,345.504 330.277,345.996 331.168,346.981C332.059,347.966 332.504,349.349 332.504,351.131L332.504,441.027C332.504,442.809 332.106,444.192 331.308,445.177C330.511,446.162 329.362,446.654 327.862,446.654C326.455,446.654 325.329,446.373 324.485,445.81C323.641,445.248 322.75,444.357 321.812,443.137L260.616,361.401L263.851,361.401L263.851,440.886C263.851,442.762 263.382,444.192 262.444,445.177C261.507,446.162 260.193,446.654 258.505,446.654Z"
            style="fill: #2A3434; fill-rule:nonzero;"/>
      <path d="M363.454,445.67C361.391,445.67 359.82,445.13 358.741,444.052C357.663,442.973 357.124,441.402 357.124,439.339L357.124,352.819C357.124,350.756 357.663,349.185 358.741,348.106C359.82,347.028 361.391,346.489 363.454,346.489L415.788,346.489C417.382,346.489 418.602,346.911 419.446,347.755C420.29,348.599 420.712,349.771 420.712,351.272C420.712,352.866 420.29,354.085 419.446,354.93C418.602,355.774 417.382,356.196 415.788,356.196L368.378,356.196L368.378,390.522L412.834,390.522C414.428,390.522 415.647,390.921 416.492,391.718C417.336,392.515 417.758,393.711 417.758,395.305C417.758,396.9 417.336,398.119 416.492,398.963C415.647,399.807 414.428,400.229 412.834,400.229L368.378,400.229L368.378,435.963L415.788,435.963C417.382,435.963 418.602,436.385 419.446,437.229C420.29,438.073 420.712,439.245 420.712,440.746C420.712,442.34 420.29,443.559 419.446,444.403C418.602,445.248 417.382,445.67 415.788,445.67L363.454,445.67Z"
            style="fill: #2A3434; fill-rule:nonzero;"/>
      <path d="M467.419,446.654C465.637,446.654 464.23,446.115 463.198,445.037C462.166,443.958 461.651,442.481 461.651,440.605L461.651,356.477L429.997,356.477C428.309,356.477 426.996,356.008 426.058,355.07C425.12,354.132 424.651,352.913 424.651,351.412C424.651,349.818 425.12,348.599 426.058,347.755C426.996,346.911 428.309,346.489 429.997,346.489L504.981,346.489C506.669,346.489 507.982,346.911 508.92,347.755C509.858,348.599 510.327,349.818 510.327,351.412C510.327,353.007 509.858,354.25 508.92,355.141C507.982,356.032 506.669,356.477 504.981,356.477L473.327,356.477L473.327,440.605C473.327,442.481 472.835,443.958 471.85,445.037C470.865,446.115 469.388,446.654 467.419,446.654Z"
            style="fill: #2A3434; fill-rule:nonzero;"/>
      <path d="M557.455,446.936C548.264,446.936 540.268,444.849 533.469,440.675C526.669,436.502 521.417,430.617 517.712,423.02C514.008,415.423 512.155,406.419 512.155,396.009C512.155,388.131 513.211,381.073 515.321,374.836C517.431,368.599 520.456,363.3 524.395,358.939C528.334,354.578 533.094,351.201 538.674,348.81C544.254,346.418 550.515,345.222 557.455,345.222C566.834,345.222 574.923,347.286 581.723,351.412C588.523,355.539 593.775,361.377 597.479,368.927C601.184,376.477 603.036,385.458 603.036,395.868C603.036,403.746 601.958,410.827 599.801,417.111C597.643,423.395 594.595,428.741 590.656,433.149C586.717,437.557 581.957,440.957 576.377,443.348C570.797,445.74 564.489,446.936 557.455,446.936ZM557.455,436.666C564.583,436.666 570.609,435.072 575.533,431.883C580.457,428.694 584.232,424.051 586.858,417.955C589.484,411.859 590.797,404.543 590.797,396.009C590.797,383.16 587.913,373.195 582.145,366.114C576.377,359.033 568.147,355.492 557.455,355.492C550.515,355.492 544.583,357.087 539.659,360.275C534.735,363.464 530.96,368.083 528.334,374.133C525.708,380.182 524.395,387.474 524.395,396.009C524.395,408.764 527.302,418.729 533.117,425.904C538.932,433.079 547.045,436.666 557.455,436.666Z"
            style="fill: #2A3434; fill-rule:nonzero;"/>
      <path d="M629.625,445.67C627.749,445.67 626.296,445.13 625.264,444.052C624.232,442.973 623.717,441.496 623.717,439.62L623.717,351.834C623.717,349.865 624.209,348.364 625.194,347.333C626.178,346.301 627.609,345.785 629.485,345.785C631.36,345.785 632.791,346.301 633.775,347.333C634.76,348.364 635.252,349.865 635.252,351.834L635.252,435.54L681.396,435.54C683.084,435.54 684.398,435.986 685.335,436.877C686.273,437.768 686.742,439.011 686.742,440.605C686.742,442.199 686.273,443.442 685.335,444.333C684.398,445.224 683.084,445.67 681.396,445.67L629.625,445.67Z"
            style="fill: #2A3434; fill-rule:nonzero;"/>
      <path d="M705.594,446.654C703.812,446.654 702.452,446.162 701.514,445.177C700.576,444.192 700.107,442.762 700.107,440.886L700.107,351.412C700.107,349.537 700.576,348.083 701.514,347.051C702.452,346.02 703.671,345.504 705.172,345.504C706.672,345.504 707.774,345.762 708.478,346.278C709.181,346.793 710.002,347.708 710.94,349.021L772.277,430.757L768.901,430.757L768.901,351.131C768.901,349.349 769.37,347.966 770.308,346.981C771.245,345.996 772.605,345.504 774.387,345.504C776.075,345.504 777.365,345.996 778.256,346.981C779.147,347.966 779.593,349.349 779.593,351.131L779.593,441.027C779.593,442.809 779.194,444.192 778.397,445.177C777.6,446.162 776.451,446.654 774.95,446.654C773.543,446.654 772.418,446.373 771.574,445.81C770.73,445.248 769.839,444.357 768.901,443.137L707.704,361.401L710.94,361.401L710.94,440.886C710.94,442.762 710.471,444.192 709.533,445.177C708.595,446.162 707.282,446.654 705.594,446.654ZM722.898,467.616C720.178,467.616 718.818,466.35 718.818,463.818C718.818,462.505 719.169,461.52 719.873,460.863C720.576,460.207 721.585,459.879 722.898,459.879L756.943,459.879C759.756,459.879 761.163,461.192 761.163,463.818C761.163,464.943 760.811,465.857 760.108,466.561C759.405,467.264 758.35,467.616 756.943,467.616L722.898,467.616Z"
            style="fill: #2A3434; fill-rule:nonzero;"/>
      <path d="M810.543,445.67C808.479,445.67 806.908,445.13 805.83,444.052C804.751,442.973 804.212,441.402 804.212,439.339L804.212,352.819C804.212,350.756 804.751,349.185 805.83,348.106C806.908,347.028 808.479,346.489 810.543,346.489L862.876,346.489C864.471,346.489 865.69,346.911 866.534,347.755C867.378,348.599 867.8,349.771 867.8,351.272C867.8,352.866 867.378,354.085 866.534,354.93C865.69,355.774 864.471,356.196 862.876,356.196L815.467,356.196L815.467,390.522L859.922,390.522C861.517,390.522 862.736,390.921 863.58,391.718C864.424,392.515 864.846,393.711 864.846,395.305C864.846,396.9 864.424,398.119 863.58,398.963C862.736,399.807 861.517,400.229 859.922,400.229L815.467,400.229L815.467,435.963L862.876,435.963C864.471,435.963 865.69,436.385 866.534,437.229C867.378,438.073 867.8,439.245 867.8,440.746C867.8,442.34 867.378,443.559 866.534,444.403C865.69,445.248 864.471,445.67 862.876,445.67L810.543,445.67Z"
            style="fill: #2A3434; fill-rule:nonzero;"/>
      <path d="M917.039,446.654C915.351,446.654 913.991,446.209 912.959,445.318C911.928,444.427 911.084,443.09 910.427,441.308L879.336,352.538C878.68,350.568 878.844,348.904 879.829,347.544C880.813,346.184 882.384,345.504 884.542,345.504C886.136,345.504 887.402,345.902 888.34,346.7C889.278,347.497 890.075,348.739 890.732,350.428L919.853,435.822L914.929,435.822L944.613,350.85C945.269,349.068 946.067,347.731 947.004,346.84C947.942,345.949 949.209,345.504 950.803,345.504C952.304,345.504 953.499,345.949 954.39,346.84C955.281,347.731 956.055,349.068 956.712,350.85L985.973,435.822L981.612,435.822L1011.01,350.428C1011.48,348.833 1012.21,347.614 1013.2,346.77C1014.18,345.926 1015.47,345.504 1017.06,345.504C1019.03,345.504 1020.49,346.16 1021.43,347.473C1022.36,348.786 1022.46,350.475 1021.71,352.538L990.475,441.308C989.819,443.09 988.998,444.427 988.013,445.318C987.029,446.209 985.645,446.654 983.863,446.654C982.175,446.654 980.839,446.209 979.854,445.318C978.869,444.427 978.001,443.09 977.251,441.308L948.974,359.853L952.069,359.853L923.511,441.308C922.854,443.09 922.057,444.427 921.119,445.318C920.181,446.209 918.821,446.654 917.039,446.654Z"
            style="fill: #2A3434; fill-rule:nonzero;"/>
      <path d="M933.499,467.616C930.779,467.616 929.419,466.35 929.419,463.818C929.419,462.505 929.771,461.52 930.474,460.863C931.178,460.207 932.186,459.879 933.499,459.879L967.544,459.879C970.358,459.879 971.765,461.192 971.765,463.818C971.765,464.943 971.413,465.857 970.709,466.561C970.006,467.264 968.951,467.616 967.544,467.616L933.499,467.616Z"
            style="fill: #2A3434; fill-rule:nonzero;"/>
    </g>
    <g transform="matrix(0.707107,0.707107,-0.707107,0.707107,312.174,-406.762)">
      <path d="M258.787,446.654C256.911,446.654 255.481,446.115 254.496,445.037C253.511,443.958 253.019,442.481 253.019,440.605L253.019,351.553C253.019,349.584 253.511,348.083 254.496,347.051C255.481,346.02 256.911,345.504 258.787,345.504C260.662,345.504 262.093,346.02 263.077,347.051C264.062,348.083 264.555,349.584 264.555,351.553L264.555,440.605C264.555,442.481 264.086,443.958 263.148,445.037C262.21,446.115 260.756,446.654 258.787,446.654Z"
            style="fill: #2A3434; fill-rule:nonzero;"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "SvgNetolnewLogo",

}
</script>

<style scoped>
svg.netolnew-logo.grey-text > g > path {
  fill: #717878!important;
}

svg.netolnew-logo.blue-on-hover > g > path {
  fill: #0B7DA1!important;
}
</style>