<template>
  <svg
      class="netolnew-logo"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 312 55"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.912,46.453c-0.525,-0 -0.928,-0.15 -1.209,-0.45c-0.281,-0.3 -0.422,-0.732 -0.422,-1.294l0,-36.788c0,-0.6 0.131,-1.05 0.394,-1.35c0.262,-0.3 0.619,-0.45 1.069,-0.45c0.487,0 0.843,0.075 1.068,0.225c0.225,0.15 0.469,0.432 0.732,0.844l25.875,34.819l-1.294,-0l-0,-34.2c-0,-0.563 0.141,-0.985 0.422,-1.266c0.281,-0.281 0.684,-0.422 1.209,-0.422c0.488,0 0.872,0.141 1.153,0.422c0.282,0.281 0.422,0.703 0.422,1.266l0,36.9c0,0.562 -0.122,0.994 -0.365,1.294c-0.244,0.3 -0.591,0.45 -1.041,0.45c-0.413,-0 -0.75,-0.085 -1.013,-0.254c-0.262,-0.168 -0.543,-0.44 -0.843,-0.815l-25.819,-34.819l1.237,0l0,34.144c0,0.562 -0.14,0.994 -0.421,1.294c-0.282,0.3 -0.666,0.45 -1.154,0.45Z" style="fill:#2a3434;fill-rule:nonzero;"/>
    <path d="M43.594,46.115c-0.6,0 -1.069,-0.169 -1.407,-0.506c-0.337,-0.338 -0.506,-0.825 -0.506,-1.463l0,-35.718c0,-0.638 0.169,-1.125 0.506,-1.463c0.338,-0.337 0.807,-0.506 1.407,-0.506l21.262,-0c0.525,-0 0.919,0.131 1.181,0.394c0.263,0.262 0.394,0.618 0.394,1.068c0,0.525 -0.131,0.91 -0.394,1.153c-0.262,0.244 -0.656,0.366 -1.181,0.366l-19.8,0l0,15.075l18.675,0c0.525,0 0.919,0.122 1.181,0.366c0.263,0.243 0.394,0.609 0.394,1.097c0,0.487 -0.131,0.862 -0.394,1.125c-0.262,0.262 -0.656,0.393 -1.181,0.393l-18.675,0l0,15.638l19.8,-0c0.525,-0 0.919,0.122 1.181,0.365c0.263,0.244 0.394,0.61 0.394,1.097c0,0.488 -0.131,0.863 -0.394,1.125c-0.262,0.263 -0.656,0.394 -1.181,0.394l-21.262,0Z"
          style="fill:#2a3434;fill-rule:nonzero;"/>
    <path d="M84.656,46.453c-0.562,-0 -0.994,-0.16 -1.294,-0.479c-0.3,-0.318 -0.45,-0.759 -0.45,-1.321l0,-35.1l-13.443,-0c-0.525,-0 -0.938,-0.15 -1.238,-0.45c-0.3,-0.3 -0.45,-0.675 -0.45,-1.125c0,-0.488 0.15,-0.863 0.45,-1.125c0.3,-0.263 0.713,-0.394 1.238,-0.394l30.431,-0c0.525,-0 0.928,0.131 1.209,0.394c0.282,0.262 0.422,0.637 0.422,1.125c0,0.487 -0.14,0.871 -0.422,1.153c-0.281,0.281 -0.684,0.422 -1.209,0.422l-13.5,-0l-0,35.1c-0,0.562 -0.141,1.003 -0.422,1.321c-0.281,0.319 -0.722,0.479 -1.322,0.479Z"
          style="fill:#2a3434;fill-rule:nonzero;"/>
    <path d="M120.15,46.565c-3.6,0 -6.731,-0.825 -9.394,-2.475c-2.662,-1.65 -4.715,-3.994 -6.159,-7.031c-1.444,-3.038 -2.166,-6.638 -2.166,-10.8c0,-3.15 0.403,-5.972 1.21,-8.466c0.806,-2.494 1.978,-4.612 3.515,-6.356c1.538,-1.744 3.403,-3.084 5.597,-4.022c2.194,-0.937 4.66,-1.406 7.397,-1.406c3.675,-0 6.844,0.815 9.506,2.447c2.663,1.631 4.707,3.956 6.131,6.975c1.425,3.018 2.138,6.609 2.138,10.772c-0,3.15 -0.413,5.981 -1.238,8.493c-0.825,2.513 -2.006,4.65 -3.543,6.413c-1.538,1.762 -3.403,3.112 -5.597,4.05c-2.194,0.937 -4.66,1.406 -7.397,1.406Zm-0,-3.15c3,0 5.55,-0.675 7.65,-2.025c2.1,-1.35 3.703,-3.3 4.809,-5.85c1.107,-2.55 1.66,-5.644 1.66,-9.281c-0,-5.438 -1.228,-9.647 -3.685,-12.628c-2.456,-2.982 -5.934,-4.472 -10.434,-4.472c-2.963,-0 -5.494,0.675 -7.594,2.025c-2.1,1.35 -3.703,3.3 -4.809,5.85c-1.106,2.55 -1.66,5.625 -1.66,9.225c0,5.4 1.238,9.609 3.713,12.628c2.475,3.019 5.925,4.528 10.35,4.528Z"
          style="fill:#2a3434;fill-rule:nonzero;"/>
    <path d="M148.444,46.115c-0.525,0 -0.947,-0.159 -1.266,-0.478c-0.319,-0.319 -0.478,-0.759 -0.478,-1.322l0,-36.337c0,-0.6 0.15,-1.05 0.45,-1.35c0.3,-0.3 0.713,-0.45 1.238,-0.45c0.562,-0 0.993,0.15 1.293,0.45c0.3,0.3 0.45,0.75 0.45,1.35l0,35.043l19.35,0c0.563,0 0.985,0.132 1.266,0.394c0.281,0.263 0.422,0.638 0.422,1.125c-0,0.525 -0.141,0.919 -0.422,1.181c-0.281,0.263 -0.703,0.394 -1.266,0.394l-21.037,0Z"
          style="fill:#2a3434;fill-rule:nonzero;"/>
    <path d="M178.425,46.453c-0.525,-0 -0.928,-0.15 -1.209,-0.45c-0.282,-0.3 -0.422,-0.732 -0.422,-1.294l-0,-36.788c-0,-0.6 0.131,-1.05 0.393,-1.35c0.263,-0.3 0.619,-0.45 1.069,-0.45c0.488,0 0.844,0.075 1.069,0.225c0.225,0.15 0.469,0.432 0.731,0.844l25.875,34.819l-1.293,-0l-0,-34.2c-0,-0.563 0.14,-0.985 0.421,-1.266c0.282,-0.281 0.685,-0.422 1.21,-0.422c0.487,0 0.872,0.141 1.153,0.422c0.281,0.281 0.422,0.703 0.422,1.266l-0,36.9c-0,0.562 -0.122,0.994 -0.366,1.294c-0.244,0.3 -0.59,0.45 -1.04,0.45c-0.413,-0 -0.75,-0.085 -1.013,-0.254c-0.262,-0.168 -0.544,-0.44 -0.844,-0.815l-25.818,-34.819l1.237,0l0,34.144c0,0.562 -0.141,0.994 -0.422,1.294c-0.281,0.3 -0.665,0.45 -1.153,0.45Zm7.144,8.212c-0.938,0 -1.406,-0.431 -1.406,-1.294c-0,-0.412 0.121,-0.731 0.365,-0.956c0.244,-0.225 0.591,-0.337 1.041,-0.337l13.5,-0c0.937,-0 1.406,0.431 1.406,1.293c0,0.413 -0.122,0.732 -0.366,0.957c-0.243,0.225 -0.59,0.337 -1.04,0.337l-13.5,0Z"
          style="fill:#2a3434;fill-rule:nonzero;"/>
    <path d="M220.106,46.115c-0.6,0 -1.069,-0.169 -1.406,-0.506c-0.338,-0.338 -0.506,-0.825 -0.506,-1.463l-0,-35.718c-0,-0.638 0.168,-1.125 0.506,-1.463c0.337,-0.337 0.806,-0.506 1.406,-0.506l21.263,-0c0.525,-0 0.918,0.131 1.181,0.394c0.262,0.262 0.394,0.618 0.394,1.068c-0,0.525 -0.132,0.91 -0.394,1.153c-0.263,0.244 -0.656,0.366 -1.181,0.366l-19.8,0l-0,15.075l18.675,0c0.525,0 0.918,0.122 1.181,0.366c0.262,0.243 0.394,0.609 0.394,1.097c-0,0.487 -0.132,0.862 -0.394,1.125c-0.263,0.262 -0.656,0.393 -1.181,0.393l-18.675,0l-0,15.638l19.8,-0c0.525,-0 0.918,0.122 1.181,0.365c0.262,0.244 0.394,0.61 0.394,1.097c-0,0.488 -0.132,0.863 -0.394,1.125c-0.263,0.263 -0.656,0.394 -1.181,0.394l-21.263,0Z"
          style="fill:#2a3434;fill-rule:nonzero;"/>
    <path d="M262.294,46.453c-0.525,-0 -0.947,-0.141 -1.266,-0.422c-0.319,-0.282 -0.59,-0.703 -0.815,-1.266l-12.713,-36.562c-0.187,-0.6 -0.141,-1.097 0.141,-1.491c0.281,-0.394 0.74,-0.591 1.378,-0.591c0.487,0 0.881,0.113 1.181,0.338c0.3,0.225 0.544,0.619 0.731,1.181l12.319,36l-1.8,0l12.431,-35.831c0.225,-0.563 0.478,-0.985 0.76,-1.266c0.281,-0.281 0.684,-0.422 1.209,-0.422c0.488,0 0.872,0.141 1.153,0.422c0.281,0.281 0.516,0.703 0.703,1.266l12.375,35.831l-1.687,0l12.431,-36c0.15,-0.525 0.384,-0.909 0.703,-1.153c0.319,-0.244 0.722,-0.366 1.21,-0.366c0.6,0 1.04,0.188 1.321,0.563c0.282,0.375 0.291,0.881 0.029,1.519l-12.713,36.562c-0.225,0.563 -0.487,0.984 -0.787,1.266c-0.3,0.281 -0.732,0.422 -1.294,0.422c-0.525,-0 -0.947,-0.141 -1.266,-0.422c-0.319,-0.282 -0.59,-0.703 -0.815,-1.266l-11.982,-34.706l1.125,-0l-12.037,34.706c-0.225,0.563 -0.478,0.984 -0.76,1.266c-0.281,0.281 -0.703,0.422 -1.265,0.422Z"
          style="fill:#2a3434;fill-rule:nonzero;"/>
    <path d="M269.1,54.665c-0.938,0 -1.406,-0.431 -1.406,-1.294c-0,-0.412 0.122,-0.731 0.365,-0.956c0.244,-0.225 0.591,-0.337 1.041,-0.337l13.5,-0c0.938,-0 1.406,0.431 1.406,1.293c0,0.413 -0.122,0.732 -0.365,0.957c-0.244,0.225 -0.591,0.337 -1.041,0.337l-13.5,0Z"
          style="fill:#2a3434;fill-rule:nonzero;"/>
    <path d="M71.275,41.464c-0.371,-0.371 -0.55,-0.776 -0.537,-1.213c0.013,-0.438 0.219,-0.855 0.616,-1.253l25.973,-25.973c0.425,-0.424 0.849,-0.636 1.273,-0.636c0.424,-0 0.822,0.185 1.193,0.556c0.398,0.398 0.597,0.809 0.597,1.233c-0,0.425 -0.212,0.849 -0.636,1.273l-25.973,25.973c-0.398,0.398 -0.809,0.61 -1.233,0.637c-0.425,0.026 -0.849,-0.173 -1.273,-0.597Z"
          style="fill:#2a3434;fill-rule:nonzero;"/>
  </svg>
</template>

<script>
export default {
  name: "SvgNetolnewLogoThin",

}
</script>

<style scoped>
svg.netolnew-logo.grey-text > path {
  fill: #717878!important;
}
</style>