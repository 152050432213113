<template>
  <div class="not-found space-y-24 my-40 lg:mt-64">
    <section>
      <div class="container space-y-8">
        <h1>Woah, how'd ya get here?</h1>
        <p class="text-xl">Get back on track by heading by <router-link :to="{name: 'Home'}">home</router-link>.</p>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>