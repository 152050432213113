<template>
  <div class="tag rounded-md p-2">
    <p class="leading-4">
      <slot name="tagContent">Tag Content</slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "TagItem",
  props: {
    // customClass: {
    //   type: String,
    //   default: "primary-tag",
    // },
  }
}
</script>

<style scoped>
/******************** TAGS ********************/
.tag, .tag.primary-tag {
  @apply bg-blue-50
}

.tag, .tag.primary-tag > p {
  @apply text-blue-500
}

.secondary-tag {
  @apply bg-grey-50
}

.secondary-tag > p {
  @apply text-grey-300
}
</style>