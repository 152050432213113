<template>
  <div class="gamestop-canada space-y-32 my-40 lg:mt-64">

    <section class="hero intro">
      <div class="container grid grid-cols-6 md:grid-cols-12 gap-8 justify-items-center	items-center">
        <div class="col-span-6 md:col-span-8 xl:col-span-6 space-y-4">
          <div class="row">
            <p class="caption">UX/UI Case Study</p>
            <h1 class="hero">GameStop Canada Website Redesign</h1>
            <p class="text-xl lg:text-2xl font-thin text-grey-300">Enhancing GameStop Canada's shopping experience by
              helping customers find the products they are looking for quickly and easily.</p>
          </div>

          <div class="tag-container hidden md:flex flex-wrap gap-y-2 gap-x-2">
            <tag-item :class="'primary-tag'">
              <template #tagContent>UX Design</template>
            </tag-item>
            <tag-item :class="'primary-tag'">
              <template #tagContent>UI Design</template>
            </tag-item>
            <tag-item :class="'secondary-tag'">
              <template #tagContent>E-Commerce</template>
            </tag-item>
          </div>
        </div>

        <div class="col-span-6 md:col-span-4 xl:col-span-6">
          <img alt="" class=""
               src="../assets/img/hero/project-gamestop.png">
        </div>
      </div>
    </section>

    <section id="project-overview" ref="project-overview" class="container space-y-16">
      <h1 class="animate-container text-center">Overview</h1>

      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <h2 class="text-blue-600">Background</h2>
          <p class="text-xl">
            As both a designer and a customer of GameStop Canada, I couldn't help but notice that their website could
            use some work. It is outdated, inconsistent, and difficult to navigate making it hard for users to find the
            product they're looking for. This inspired me to take on a redesign project.
          </p>
        </div>

        <div class="border-l-2 border-purple-500 pl-4">
          <p class="text-grey-300 italic"><span class="font-bold">Disclaimer:</span> This project is a theoretical
            redesign of an existing website and has been created solely for the purpose of improving my own design
            skills. I do not work for GameStop Canada and am unaware of the constraints that the team of designers and
            developers are working with. </p>
        </div>
      </div>

      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <h2 class="text-blue-600">Goal</h2>
          <p class="text-xl">
            Make it easier for users to find the products they are looking for on GameStop Canada's website.
          </p>
        </div>
      </div>

      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <h2 class="text-blue-600">Final Designs</h2>
          <p class="text-xl">
            Focusing my efforts on making the website easy to use, providing relevant information to users, and
            improving the overall aesthetics and accessibility of the website, I redesigned the search functionality,
            menu navigation, home page, product listings, and product details.
          </p>
        </div>
      </div>

      <img alt="ADD ALT" class="rounded-md"
           src="../assets/img/gamestop/final-designs/before-after-collage-whitebg.jpg">
    </section>

    <section id="project-research" class="container space-y-16">
      <h1 class="text-center">User Research</h1>

      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <h2 class="text-blue-600">Survey</h2>
          <p class="text-xl">
            To start, I conducted an online survey to learn more about gamers' online shopping habits as well as
            awareness and experience with GameStop.
          </p>
        </div>

        <div class="subsection-content grid grid-cols-12 gap-4">
          <div class="column col-span-12 lg:col-span-6 space-y-4">
            <div class="flex space-x-4">
              <div class="icon-container">
                <user-search-icon class="icon-lg icon-stroke-light text-blue-500"/>
              </div>
              <div class="text-container">
                <p class="caption">Target Audience</p>
                <p>
                  People who shop online for video games or gaming accessories and merchandise.
                </p>
              </div>
            </div>

            <div class="flex space-x-4">
              <div class="icon-container">
                <checkbox-icon class="icon-lg icon-stroke-light text-blue-500"/>
              </div>
              <div class="text-container">
                <p class="caption">Survey Questions</p>
                <p>
                  Questions were designed to gain a deeper understanding of who is shopping, what they are shopping
                  for,
                  and what factors are important to them when shopping online. I also wanted to gauge users'
                  familiarity
                  with GameStop and how their experience has been with the company.
                </p>
              </div>
            </div>
          </div>

          <div class="column col-span-12 lg:col-span-6">
            <img alt="" src="../assets/img/gamestop/survey-screenshot.jpg"
                 class="rounded-md shadow-lg shadow-grey-500/10 ">
          </div>
        </div>
      </div>


      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <h2 class="text-blue-600">Insights</h2>
          <p class="text-xl">
            After analyzing the responses, I learned that those shopping for video games and accessories are not
            simply gamers - they are parents shopping for children, friends shopping for gifts, console gamers, PC
            gamers etc. Diving further into the responses, the priorities and preferences of online shoppers becomes
            clear.
          </p>
        </div>

        <div class="grid gap-4 md:grid-cols-12 md:gap-8">
          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <coin-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Insight #1</p>

            <div class="space-y-1">
              <p class="font-bold text-lg">Gaming is expensive and some users are price conscious. </p>
              <p>Free shipping is very appealing to most (64%) while 33% mentioned finding the best prices
                (unprompted).</p>
            </div>
          </div>

          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <hand-click-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Insight #2</p>

            <div class="space-y-1">
              <p class="font-bold text-lg">The online shopping experience should be quick and easy. </p>
              <p>Shopping online should save time - 75% of users want to find what they are looking for quickly and
                easily. </p>
            </div>
          </div>

          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <focus2-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Insight #3</p>

            <div class="space-y-1">
              <p class="font-bold text-lg">Users want experiences that are tailored to them.</p>
              <p>Users want to see information that is relevant to them such as stock at a nearby store and other
                products
                that might interest them.</p>
            </div>
          </div>

          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <star-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Insight #4</p>

            <div class="space-y-1">
              <p class="font-bold text-lg">Ratings and reviews help users make purchase decisions.</p>
              <p>The majority (64%) of users report reading online reviews before purchasing a product. </p>
            </div>
          </div>
        </div>
      </div>

      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <h2 class="text-blue-600">Issues</h2>
          <p class="text-xl">
            Among shoppers who have previously purchased from GameStop, the following issues came to light.
          </p>
        </div>

        <div class="grid gap-4 md:grid-cols-12 md:gap-8">
          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <icon-question-circle class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Issue #1</p>

            <div class="space-y-1">
              <p class="font-bold text-lg">GameStop's customers are unaware of their full product offering. </p>
              <p>
                Customers want to purchase gaming furniture but don't know that GameStop sells chairs, desks, or
                monitors because they are difficult to find on the website.
              </p>
            </div>
          </div>

          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <mood-wrrr-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Issue #2</p>

            <div class="space-y-1">
              <p class="font-bold text-lg">Some trust has already been lost among customers.</p>
              <p>
                There are reports of GameStop customers paying full price for a 'new' game online only to receive and
                open game from in-store displays.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="project-personas" class="container space-y-16">
      <h1 class="text-center">Personas</h1>
      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            To ensure my design solutions met the needs and goals of GameStop's users, I created three personas.
          </p>
          <p>
            These personas reflect the different types of shoppers and gamers that were discovered in my user
            research. I used the personas as a reference throughout the remainder of the design process to keep me
            focused on the goals and pain points of the user.
          </p>
        </div>

        <div class="column col-span-6 lg:col-span-6">
          <image-carousel :slides="personaSlides"></image-carousel>
        </div>
      </div>
    </section>

    <section id="project-evaluation" class="container space-y-16">
      <h1 class="text-center">Heuristic Evaluation</h1>

      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <h2 class="text-blue-600">Evaluation</h2>
          <p class="text-xl">
            With my personas in mind, I conducted a Heuristic Evaluation to identify GameStop Canada's strengths and
            weaknesses, and to find any bugs or usability issues that need addressing.
          </p>
        </div>

        <div class="subsection-content grid grid-cols-12 gap-4">
          <div class="column col-span-12 lg:col-span-6 space-y-4">
            <div class="flex gap-4">
              <div class="icon-container">
                <checklist-icon class="icon-lg icon-stroke-light text-blue-500"/>
              </div>
              <div class="text-container">
                <p class="caption">Evaluation</p>
                <p>
                  The website was broken down into 10 categories, each with a different list of evaluation criteria.
                  Each item was given a rating based on whether the site complies and how severe the issue is.
                </p>
              </div>
            </div>

            <div class="flex space-x-4">
              <div class="icon-container">
                <trophy-icon class="icon-lg icon-stroke-light text-blue-500"/>
              </div>
              <div class="text-container">
                <p class="caption">Scoring</p>
                <p>
                  A score for each category was calculated based on the number and severity of non-complying criteria
                  which helped indicate the areas of the website that should be prioritized.
                </p>
              </div>
            </div>
          </div>

          <div class="column col-span-12 lg:col-span-6">
            <img alt="" src="../assets/img/gamestop/evaluation-collage@2x.png" class="rounded-md">
          </div>
        </div>
      </div>

      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <h2 class="text-blue-600">Results</h2>
          <p class="text-xl">
            Based on the scores for each category, my evaluation revealed the following priority areas.
          </p>
        </div>

        <div class="grid md:grid-cols-6 grid-cols-1 gap-8 text-center justify-center">
          <div class="md:col-span-2 grid grid-cols-1 grid-rows-1">
            <div class="col-start-1 row-start-1 self-center text-blue-50 text-5xl font-bold">01</div>
            <div class="col-start-1 row-start-1 self-center">
              <p>Search Usability</p>
            </div>
          </div>

          <div class="md:col-span-2 grid grid-cols-1 grid-rows-1">
            <div class="col-start-1 row-start-1 self-center text-blue-50 text-5xl font-bold">02</div>
            <div class="col-start-1 row-start-1 self-center">
              <p>Navigation & Information Architecture</p>
            </div>
          </div>

          <div class="md:col-span-2 grid grid-cols-1 grid-rows-1">
            <div class="col-start-1 row-start-1 self-center text-blue-50 text-5xl font-bold">03</div>
            <div class="col-start-1 row-start-1 self-center">
              <p>Layout & Visual Design</p>
            </div>
          </div>

          <div class="md:col-span-3 grid grid-cols-1 grid-rows-1">
            <div class="col-start-1 row-start-1 self-center text-grey-50 text-5xl font-bold">04</div>
            <div class="col-start-1 row-start-1 self-center">
              <p>Home Page</p>
            </div>
          </div>

          <div class="md:col-span-3 grid grid-cols-1 grid-rows-1">
            <div class="col-start-1 row-start-1 self-center text-grey-50 text-5xl font-bold">05</div>
            <div class="col-start-1 row-start-1 self-center">
              <p>Shopping Experience</p>
            </div>
          </div>
        </div>
      </div>

      <div class="subsection-container space-y-8">
        <p class="text-xl">
          Users are having to work way too hard to find the products they are looking for as the site
          suffers from poor search functionality, unintuitive product categorization, and inconsistent, inaccessible
          UI. <span class="text-grey-300">Scroll through some of the issues below.</span>
        </p>

        <image-carousel :slides="evaluationSlides"></image-carousel>
      </div>
    </section>

    <section id="project-objectives" class="container space-y-16">
      <h1 class="text-center">Objectives</h1>

      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <p class="text-xl">
            Now that I had a good idea of what users wanted and how GameStop's website is currently meeting those needs,
            I set some objectives for myself to help me focus on which elements of the user experience to improve.
          </p>
        </div>

        <div class="p-6 space-x-4 bg-blue-50 flex rounded-md">
          <icon-target-arrow class="icon-lg icon-stroke-light text-blue-500"/>
          <h2 class="text-blue-500 w-full">
            How might we provide users with a delightful, tailored shopping experience that builds
            trust and loyalty?
          </h2>
        </div>

        <div class="grid gap-4 md:grid-cols-12 md:gap-8">
          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <compass-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Objective #1</p>

            <div class="space-y-1">
              <p class="text-lg">
                Help users find the products they want while encouraging the discovery of other products.
              </p>
            </div>
          </div>

          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <adjustments-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Objective #2</p>

            <div class="space-y-1">
              <p class="text-lg">
                Offer users a more personalized shopping experience by providing information that is relevant to them.
              </p>
            </div>
          </div>

          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <heart-handshake-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Objective #3</p>

            <div class="space-y-1">
              <p class="text-lg">
                Be open and transparent with users to build trust and keep them coming back.
              </p>
            </div>
          </div>

          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <tools-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Objective #4</p>

            <div class="space-y-1">
              <p class="text-lg">
                Improve the overall aesthetics of the site while ensuring UI elements are accessible for all users.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="project-competitive-analysis" class="container space-y-16">
      <h1 class="text-center">Competitive Analysis</h1>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            With my objectives defined, I conducted a competitive analysis to help identify the strengths and
            weaknesses of GameStop Canada's competitors and give me a more solid understanding of the e-commerce
            industry.
          </p>
          <p>
            On the lookout for industry design patterns and features that might help me meet my objectives, I found the
            following.
          </p>

          <ul>
            <li>Menus are flexible, robust, and product categorization makes sense</li>
            <li>Search functionality helps users find what they are looking for by offering relevant suggestions</li>
            <li>Product descriptions and specifications are informative</li>
            <li>Big-Box stores include customer reviews and photos and allow users to set their location to see stock
              at nearby stores
            </li>
          </ul>
        </div>

        <div class="column col-span-6 lg:col-span-6">
          <img src="../assets/img/gamestop/competitive-analysis-collage.png">
        </div>
      </div>
    </section>

    <section id="project-brainstorming" class="container space-y-16">
      <h1 class="text-center">Brainstorming</h1>

      <div class="subsection-container space-y-8">
        <div class="section-lead-in space-y-4">
          <p class="text-xl">
            Equipped with a new foundation of e-commerce design patterns and my objectives, it was time to start
            brainstorming and sketching some solutions to meet the needs of GameStop Canada's users.
          </p>
        </div>

        <div class="grid gap-4 md:grid-cols-12 md:gap-8">
          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <compass-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Objective #1</p>

            <div class="space-y-1">
              <p class="text-lg font-bold">
                Making Navigation Effortless
              </p>
              <ul>
                <li>Enhance UX of the search functionality to include recent searches, string suggestions, category
                  searches
                </li>
                <li>Revamp main navigation and product categorization</li>
                <li>Showcase GameStop's depth of products</li>
              </ul>
            </div>
          </div>

          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <adjustments-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Objective #2</p>

            <div class="space-y-1">
              <p class="text-lg font-bold">
                Personalizing the Experience
              </p>
              <ul>
                <li>Allow users to set a local store so they can see information that is relevant to them</li>
                <li>Show users which products they have recently viewed so they can easily recall a previous journey
                </li>
              </ul>
            </div>
          </div>

          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <heart-handshake-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Objective #3</p>

            <div class="space-y-1">
              <p class="text-lg font-bold">
                Building Trust
              </p>
              <ul>
                <li>Add customer reviews for users to browse before purchase</li>
                <li>Inform users of the condition of the games they are buying so they are not frustrated when
                  receiving open games
                </li>
                <li>Include product descriptions that help users make a decision</li>
              </ul>
            </div>
          </div>

          <div class="col-span-6 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <tools-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Objective #4</p>

            <div class="space-y-1">
              <p class="text-lg font-bold">
                Designing a System
              </p>
              <ul>
                <li>Define universal styles that will be used throughout the site</li>
                <li>Design reusable components to ensure consistency</li>
                <li>Ensure styles and components are accessible to all users</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="project-sketching" class="container space-y-16">
      <h1 class="text-center">Sketching</h1>

      <div class="subsection-container space-y-8">
        <div class="subsection-content space-y-4">
          <p class="text-xl">
            Next it was time to start sketching and get my ideas on paper. With the goal of making it easier for users
            to find what they’re looking for, I decided to redesign the search function, menu navigation, home page,
            product listings, and product details.
          </p>
          <p>
            To start, I conducted an inventory audit and categorized GameStop’s product to help inform my designs for
            the main navigation and what content to include on the home page. This is when I realized I needed to do
            some A/B testing with users to decide which menu option was more intuitive - showing gaming platforms (Xbox,
            PlayStation, Nintendo, PC) as categories versus product categories (Video Games, Consoles & Hardware, Gaming
            Accessories, etc.).
          </p>
          <p>
            Continuing with my sketching, I moved on to the search functionality, product listings, and product
            details pages, while looking for ways to provide a personalized experience, build trust, and ensure
            accessibility for all users.
          </p>
        </div>

        <img alt="ADD ALT" class="rounded-md shadow-lg shadow-grey-500/5"
             src="../assets/img/gamestop/sketches/all-sketches-collage.jpg">
      </div>
    </section>

    <section id="project-wireframes" class="container space-y-16">
      <h1 class="text-center">Wireframes</h1>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            With some concepts on paper, I started moving the best ones over to Figma to further refine the layout,
            content, and functionality of each page.
          </p>
          <p>
            I started by mocking up the two options for the menu navigation that I wanted to test with users so I
            could run a short survey while I continued to build out the remaining screens. Along the way, I did some
            basic user flow testing with my personas goals and frustrations in mind and further refined the designs.
          </p>
        </div>

        <div class="column col-span-6 lg:col-span-6">
          <img src="../assets/img/gamestop/wireframes/wireframes-collage-2.png">
        </div>
      </div>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            The results from the A/B testing were pretty close (a 60/40 split) with the majority preferring product
            categories in the menu.
          </p>
          <p>
            Since the results were close, I decided to show product categories in the main navigation while still
            including gaming platforms below the carousel for the 39% of users who preferred shopping by platform.
          </p>
        </div>

        <div class="column col-span-6 order-last lg:col-span-6 lg:order-first">
          <img src="../assets/img/gamestop/wireframes/wireframes-collage-1.png">
        </div>
      </div>
    </section>

    <section id="project-visual-design" class="container space-y-16">
      <h1 class="text-center">Visual Design</h1>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            At last, it's finally time to start putting it all together by building a visual language to use in my
            final designs.
          </p>
          <p>
            I made some small tweaks to the colour palette, updated the font family, and used the <a
              href="https://tabler-icons.io" target="_blank">Tabler icon library</a> as a starting point for the
            visual design. These changes were to ensure accessibility and consistency throughout the design.
          </p>
        </div>

        <div class="column col-span-6 lg:col-span-6">
          <img src="../assets/img/gamestop/visual-design-collage-1.png">
        </div>
      </div>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            Building a component library as I worked through the high fidelity designs, allowed me to create a
            finished product that is consistent and easy to maintain moving forward.
          </p>
          <p>
            It also allowed me to ensure all states, messaging, and styles are consistent and allows me to make global
            changes in one place.
          </p>
        </div>

        <div class="column col-span-6 order-last lg:col-span-6 lg:order-first">
          <img src="../assets/img/gamestop/visual-design-collage-2.jpg">
        </div>
      </div>
    </section>

    <section id="project-final-designs" class="container space-y-16">
      <h1 class="text-center">Final Designs</h1>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            Revised menu structure takes user feedback into account by showing clearly defined product categories
            <span class="text-grey-300">making GameStop's product offering more obvious and therefore easier for users to find what they're looking for.</span>
          </p>
        </div>

        <div class="column col-span-6 lg:col-span-6">
          <video id="video-menu-nav" playsinline muted autoplay loop class="rounded-md shadow-lg">
            <source
                src="../assets/videos/menu-nav-2.mp4"
                type="video/mp4" class="">
          </video>
        </div>
      </div>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            Improved search functionality offers more flexibility making it easier for users to find what they're
            looking for <span class="text-grey-300">by providing recent searches, search string suggestions, relevant category suggestions, and top product matches.</span>
          </p>
        </div>

        <div class="column col-span-6 order-last lg:col-span-6 lg:order-first">
          <video id="video-search-ani" playsinline muted autoplay loop class="rounded-md shadow-lg">
            <source
                src="../assets/videos/search-ani.mp4"
                type="video/mp4" class="">
          </video>
        </div>
      </div>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            Allowing users to set their local store creates a more personalized shopping experience <span
              class="text-grey-300">by displaying stock and shipping information that is relevant to them.</span>
          </p>
        </div>

        <div class="column col-span-6 lg:col-span-6">
          <video id="video-location-select" playsinline muted autoplay loop class="rounded-md shadow-lg">
            <source
                src="../assets/videos/pdp-location-select.mp4"
                type="video/mp4" class="">
          </video>
        </div>
      </div>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            Informing users that a product is 'open box' and offering a small discount will build trust through
            transparency <span class="text-grey-300">as some GameStop customers have expressed frustration after being sent opened games after paying full price.</span>
          </p>
        </div>

        <div class="column col-span-6 order-last lg:col-span-6 lg:order-first">
          <video id="video-condition-select" playsinline muted autoplay loop class="rounded-md shadow-lg">
            <source
                src="../assets/videos/pdp-condition-selection.mp4"
                type="video/mp4" class="">
          </video>
        </div>
      </div>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            Including detailed product descriptions will help users who are on the fence make a purchase decision
            <span class="text-grey-300">by providing tech specs for games or materials and care for apparel.</span>
          </p>
        </div>

        <div class="column col-span-6 lg:col-span-6">
          <video id="video-product-description" playsinline muted autoplay loop class="rounded-md shadow-lg">
            <source
                src="../assets/videos/product-description.mp4"
                type="video/mp4" class="">
          </video>
        </div>
      </div>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 gap-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            With the majority of users reading reviews before making a purchase decision, they are an important part to
            e-commerce <span
              class="text-grey-300">particularly when they include recommendations and customer photos.</span>
          </p>
        </div>

        <div class="column col-span-6 order-last lg:col-span-6 lg:order-first">
          <video id="video-pdp-reviews" playsinline muted autoplay loop class="rounded-md shadow-lg">
            <source
                src="../assets/videos/pdp-reviews.mp4"
                type="video/mp4" class="">
          </video>
        </div>
      </div>

      <div class="section-content grid grid-cols-6 lg:grid-cols-12 space-x-8">
        <div class="column col-span-6 lg:col-span-6 space-y-4">
          <p class="text-xl">
            Showing users other products they might like and recently viewed products <span class="text-grey-300">adds a personalized shopping experience and allows users to discover new products and reminds them of previous journeys.</span>
          </p>
        </div>

        <div class="column col-span-6 lg:col-span-6">
          <video id="video-pdp-suggestions" playsinline muted autoplay loop class="rounded-md shadow-lg">
            <source
                src="../assets/videos/pdp-suggestions.mp4"
                type="video/mp4" class="">
          </video>
        </div>
      </div>
    </section>

    <section id="project-learnings" class="container space-y-16">
      <h1 class="text-center">Learnings & Challenges</h1>

      <div class="section-content space-y-4">
        <p>This was my first time designing for e-commerce so I learned a ton! I analyzed other e-commerce websites
          from a perspective I never have before and read a ton of articles on what makes a good e-commerce site. </p>
        <p><span class="font-bold">Product categorization is very important to e-commerce</span> and impacts the
          shopping experience a ton! I had a lot of trouble navigating GameStop's site and searching for products
          because of this.</p>
        <p><span class="font-bold">Never assume who your users are</span> - there were user groups I hadn't considered
          during my initial brainstorming when I started this project. </p>
        <p><span class="font-bold">Designing a system takes a lot of time and planning</span> to ensure it is usable
          across many use cases. I struggled to find a balance between a lot of simple components versus fewer more
          complex ones. I had to take a step back and think about what would be the most useful in terms of how the
          components were going to be used. </p>
        <p><span class="font-bold">Document your decisions to make life easier</span> - I took some time away from
          this project for a little bit and wish I had kept better notes along the way to make it easier to come back
          to and remember what I've previously explored and decided on. </p>
      </div>
    </section>

  </div>
</template>


<script>
import TagItem from "@/components/TagItem";
import ImageCarousel from "@/components/ImageCarousel";

import {
  CoinIcon,
  HandClickIcon,
  Focus2Icon,
  StarIcon,
  MoodWrrrIcon,
  HeartHandshakeIcon,
  UserSearchIcon,
  CheckboxIcon,
  CompassIcon,
  AdjustmentsIcon,
  ToolsIcon,
  ChecklistIcon,
  TrophyIcon,
} from "vue-tabler-icons";

import {
  IconQuestionCircle,
  IconTargetArrow,
} from "@tabler/icons-vue";


export default {
  name: 'GameStopCanada',
  components: {
    ImageCarousel,
    TagItem,
    CoinIcon,
    HandClickIcon,
    Focus2Icon,
    StarIcon,
    IconQuestionCircle,
    IconTargetArrow,
    MoodWrrrIcon,
    HeartHandshakeIcon,
    UserSearchIcon,
    CheckboxIcon,
    CompassIcon,
    AdjustmentsIcon,
    ToolsIcon,
    ChecklistIcon,
    TrophyIcon,

  },
  data() {
    return {
      personaSlides: [
        {
          image: require("../assets/img/gamestop/personas/persona-1.png")
        },
        {
          image: require("../assets/img/gamestop/personas/persona-2.png")
        },
        {
          image: require("../assets/img/gamestop/personas/persona-3.png")
        },
      ],
      evaluationSlides: [
        {
          title: "Search Usability #1",
          paragraphs: [
            "Search results are limited and seem irrelevant.",
          ],
          image: require("../assets/img/gamestop/he-details/search-usability-1.jpg")
        },
        {
          title: "Search Usability #2",
          paragraphs: [
            "Even with more specific search strings, results are unexpected and contain duplicate products.",
          ],
          image: require("../assets/img/gamestop/he-details/search-usability-2.jpg")
        },
        {
          title: "Navigation & Information Architecture #1",
          paragraphs: [
            "Main navigation is confusing in terms of visual design and product categorization.",
          ],
          image: require("../assets/img/gamestop/he-details/nav-ia-1.jpg")
        },
        {
          title: "Navigation & Information Architecture #2",
          paragraphs: [
            "Inconsistent and unorganized subcategories add to the confusion.",
          ],
          image: require("../assets/img/gamestop/he-details/nav-ia-2.jpg")
        },
        {
          title: "Navigation & Information Architecture #3",
          paragraphs: [
            "Breadcrumbs do not currently assist with navigation.",
          ],
          image: require("../assets/img/gamestop/he-details/nav-ia-3.jpg")
        },
        {
          title: "Layout & Visual Design #1",
          paragraphs: [
            "UI elements are inconsistent and inaccessible.",
          ],
          image: require("../assets/img/gamestop/he-details/layout-visual-1.jpg")
        },
        {
          title: "Layout & Visual Design #2",
          paragraphs: [
            "Current filters are not intuitive in terms of filter categories and UI.",
          ],
          image: require("../assets/img/gamestop/he-details/layout-visual-2.jpg")
        },
      ],
      addons: []
    }
  },
  methods: {
    goto(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;
      let offset = document.getElementById('nav').clientHeight;

      window.scrollTo({
        top: top - offset,
        behavior: 'smooth'
      })
    }
  },
}
</script>
<style scoped>

</style>