<template>
  <div class="home space-y-24 my-40 lg:mt-64">
    <section class="hero intro">
      <div class="container grid grid-cols-6 md:grid-cols-12 gap-8 justify-items-center items-center">
        <div class="col-span-6 md:col-span-8 xl:col-span-6">
          <div class="row">
            <h1 class="hero">Hey there, I'm Janessa</h1>

            <div class="inline-flex items-center">
              <h1 class="hero" ref="target"
                  v-motion
                  :initial="{ opacity: 0, y: 100 }"
                  :enter="{ opacity: 1, y: 0, transition: {duration: 500, ease: 'easeInOut'} }"
                  :delay="500">I'm a&nbsp;</h1>

              <h1 id="typed-text-container" class="hero" ref="target"
                  v-motion
                  :initial="{ opacity: 0, y: 100 }"
                  :enter="{ opacity: 1, y: 0, transition: {duration: 500, ease: 'easeInOut'} }"
                  :delay="500">
                <span class="font-bold typed-text">{{ typeValue }}</span>
                <span id="blinking-cursor">|</span>
                <span :class="{ typing: typeStatus }" class="cursor">&nbsp;</span>
              </h1>
            </div>


            <p id="hero-sub-title" class="text-grey-300 text-xl font-thin opacity-0">I craft enjoyable user
              experiences through transforming user and business needs into beautiful, functional interfaces that
              delight.</p>
          </div>
        </div>

        <div class="col-span-6 md:col-span-4 xl:col-span-6">
          <img alt="" class="max-h-96"
               src="../assets/img/hero/home.png">
        </div>
      </div>
    </section>

    <section class="experience container space-y-8">
      <div class="section-lead-in space-y-4 text-center">
        <h1>Experience</h1>
        <p class="text-xl text-grey-300 font-thin">Here's a quick overview of my work experience. See my
          <router-link :to="{name: 'About'}">full journey here</router-link>
          .
        </p>
      </div>

      <div class="grid gap-4 md:grid-cols-4 md:gap-8">

        <div
            class="experience-item col-span-4 md:col-span-2 xl:col-span-1 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
          <div class="flex items-center space-x-2">
            <calendar-time-icon class="icon-sm icon-stroke-light text-grey-300"/>
            <span class="caption">Overall</span>
          </div>

          <div class="space-y-1">
            <span class="text-5xl text-blue-500">8 years</span>
            <p>years at a small, fast-paced company</p>
          </div>
        </div>

        <div
            class="experience-item col-span-4 md:col-span-2 xl:col-span-1 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
          <div class="flex items-center space-x-2">
            <tools-icon class="icon-sm icon-stroke-light text-grey-300"/>
            <span class="caption">Design</span>
          </div>

          <div class="space-y-1">
            <span class="text-5xl text-blue-500">5 years</span>
            <p>UX/UI design & visual communication</p>
          </div>
        </div>

        <div
            class="experience-item col-span-4 md:col-span-2 xl:col-span-1 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
          <div class="flex items-center space-x-2">
            <code-icon class="icon-sm icon-stroke-light text-grey-300"/>
            <span class="caption">Development</span>
          </div>

          <div class="space-y-1">
            <span class="text-5xl text-blue-500">5 years</span>
            <p>front-end development</p>
          </div>
        </div>

        <div
            class="experience-item col-span-4 md:col-span-2 xl:col-span-1 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
          <div class="flex items-center space-x-2">
            <tools-icon class="icon-sm icon-stroke-light text-grey-300"/>
            <span class="caption">Freelancing</span>
          </div>

          <div class="space-y-1">
            <span class="text-5xl text-blue-500">1 year</span>
            <p>UX/UI and web design</p>
          </div>
        </div>

      </div>
    </section>


    <section class="projects container space-y-8">
      <div class="section-lead-in space-y-4 text-center">
        <h1>Projects</h1>
        <p class="text-xl text-grey-300 font-thin">Check out some of my recent projects below.</p>
      </div>

      <div class=" projects-list divide-y divide-grey-50">
        <div class="project-item-container py-16 first:pt-0">
          <router-link v-slot="{navigate}" :to="{name: 'GameStop'}">
            <div class="project-item group" role="link" @click="navigate">
              <div class="grid gap-y-4 md:grid-cols-6 md:gap-x-8">
                <div
                    class="project-image group-hover:transition-transform group-hover:scale-105 group-hover:ease-in-out group-hover:duration-500 md:col-span-2">
                  <img alt="Device Screenshot" class=""
                       src="../assets/img/gamestop/device-mockups-1-600x.png">
                </div>

                <div class="project-info md:col-span-4 space-y-2">
                  <div class="flex space-x-1">
                    <clock-icon class="icon-stroke-light text-grey-300"/>
                    <p class="caption">10 min read</p>
                  </div>

                  <h2 class="group-hover:text-blue-500">GameStop Canada</h2>
                  <p class="">Enhancing GameStop Canada's shopping experience by helping customers find the products
                    they are looking for quickly and easily.</p>

                  <div class="tag-container flex flex-wrap gap-y-2 gap-x-2">
                    <tag-item :class="'primary-tag'">
                      <template #tagContent>UX Design</template>
                    </tag-item>
                    <tag-item :class="'primary-tag'">
                      <template #tagContent>UI Design</template>
                    </tag-item>
                    <tag-item :class="'secondary-tag'">
                      <template #tagContent>E-Commerce</template>
                    </tag-item>
                  </div>

                </div>
              </div>
            </div>
          </router-link>
        </div>


        <div class="project-item-container py-16">
          <router-link v-slot="{navigate}" :to="{name: 'Netolnew'}">
            <div class="project-item group" role="link" @click="navigate">
              <div class="grid gap-y-4 md:grid-cols-6 md:gap-x-8">
                <div
                    class="project-image group-hover:transition-transform group-hover:scale-105 group-hover:ease-in-out group-hover:duration-500 md:col-span-2">
                  <img alt="Device Screenshot" class=""
                       src="../assets/img/netolnew/final-designs/device-mockups.png">
                </div>

                <div class="project-info md:col-span-4 space-y-2">
                  <div class="flex space-x-1">
                    <clock-icon class="icon-stroke-light text-grey-300"/>
                    <p class="caption">8 min read</p>
                  </div>

                  <h2 class="group-hover:text-blue-500">NEȾOLṈEW̱</h2>
                  <p class="">Improving the user experience and visual appeal of
                    <svg-netolnew-logo class="inline w-auto h-4"></svg-netolnew-logo>'s website where users can learn about indigenous language revitalization efforts and techniques in
                    Canada.
                  </p>

                  <div class="tag-container flex flex-wrap gap-y-2 gap-x-2">
                    <tag-item :class="'primary-tag'">
                      <template #tagContent>Web Design</template>
                    </tag-item>
                    <tag-item :class="'primary-tag'">
                      <template #tagContent>WordPress</template>
                    </tag-item>
                    <tag-item :class="'secondary-tag'">
                      <template #tagContent>Academic Research</template>
                    </tag-item>
                  </div>

                </div>
              </div>
            </div>
          </router-link>
        </div>


        <div class="py-16 last:pb-0">
          <p class="text-grey-300 font-thin text-xl italic text-center">More to come.</p>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
import TagItem from "@/components/TagItem";
import {
  CalendarTimeIcon,
  ToolsIcon,
  CodeIcon,
  ClockIcon,
} from "vue-tabler-icons";
import SvgNetolnewLogo from "@/components/SvgNetolnewLogo";

export default {
  name: 'AppHome',
  components: {
    SvgNetolnewLogo,
    TagItem,
    CalendarTimeIcon,
    ToolsIcon,
    CodeIcon,
    ClockIcon,
  },
  data: function () {
    return {
      typeValue: "",
      typeStatus: false,
      displayTextArray: ["Problem Solver", "Critical Thinker", "Visual Communicator", "UX/UI Designer"],
      typingSpeed: 100,
      erasingSpeed: 100,
      newTextDelay: 500,
      displayTextArrayIndex: 0,
      charIndex: 0,
    }
  },
  methods: {
    typeText() {
      if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(this.charIndex);
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.displayTextArrayIndex !== this.displayTextArray.length - 1) {
        if (this.charIndex > 0) {
          if (!this.typeStatus) this.typeStatus = true;
          this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(
              0,
              this.charIndex - 1
          );
          this.charIndex -= 1;
          setTimeout(this.eraseText, this.erasingSpeed);
        } else {
          this.typeStatus = false;
          this.displayTextArrayIndex += 1;
          if (this.displayTextArrayIndex >= this.displayTextArray.length)
            this.displayTextArrayIndex = 0;
          setTimeout(this.typeText, this.typingSpeed + 1000);
        }
      } else {
        const blinkingCursor = document.getElementById("blinking-cursor");
        blinkingCursor.style.animationPlayState = "paused"
        blinkingCursor.style.opacity = "0%";

        const heroSubTitle = document.getElementById("hero-sub-title");
        heroSubTitle.classList.remove('hidden');
        heroSubTitle.classList.add('opacity-100', 'ease-in', 'duration-300');

        return false;
      }

    },
  },
  created() {
    setTimeout(this.typeText, this.newTextDelay + 1000);
  },
}
</script>

<style scoped>
/* Cursor blinking CSS */
#blinking-cursor {
  font-size: 3rem;
  color: #2A3434;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2A3434;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2A3434;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2A3434;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2A3434;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2A3434;
  }
}
</style>