<template>
  <div id="nav" :class="(!isSmallScreen && this.scrollPosition < 32) ||
       (isSmallScreen && (!this.showMobileMenu && this.scrollPosition < 32))
       ? 'bg-transparent transition duration-250'
       : 'bg-white/80 transition duration-500 backdrop-blur-md'"
       class="fixed py-4 w-full z-[100] top-0">

    <!--DESKTOP MENU-->
    <div class="container hidden md:block">
      <div class="inline-flex justify-between items-center w-full">
        <div class="max-w-[4rem]">
          <router-link to="/">
            <svg-logo :class="this.scrollPosition < 32
                      ? 'white-logo transition duration-250'
                      : 'full-colour-logo transition duration-500'" class="logo-md"
            />
          </router-link>
        </div>

        <div class="nav-content flex gap-8 justify-end nav-items">
          <div class="px-4">
            <router-link to="/about">
                          <span :class="this.scrollPosition < 32
                        ? 'text-white transition duration-250'
                        : 'text-grey-500 transition duration-500'">About</span>
            </router-link>
          </div>

          <div class="px-4">
            <router-link to="/projects">
              <span :class="this.scrollPosition < 32
            ? 'text-white transition duration-250'
            : 'text-grey-500 transition duration-500'">Projects</span>
            </router-link>
          </div>

          <div class="px-4">
            <span @click="openResume" :class="this.scrollPosition < 32
            ? 'text-white transition duration-250'
            : 'text-grey-500 transition duration-500'">
                Resume
            </span>
          </div>
        </div>
      </div>
    </div>

    <!--MOBILE MENU-->
    <div class="container md:hidden">
      <div class="inline-flex justify-between items-center w-full">
        <div class="max-w-[3rem]">
          <router-link to="/">
            <svg-logo :class="!this.showMobileMenu && this.scrollPosition < 32
            ? 'white-logo transition duration-250'
            : 'full-colour-logo transition duration-500'" class="logo-sm"
            />
          </router-link>
        </div>

        <!--        TODO CAN I CHANGE THE ICON HERE TO CLOSE after opened?-->
        <menu2-icon
            :class="this.showMobileMenu || this.scrollPosition > 32
            ? 'text-grey-500'
            : 'text-white'" @click="showMenu()">

        </menu2-icon>
      </div>

      <div :class="this.showMobileMenu ? 'open-menu transition duration-500' : 'closed-menu duration-250'"
           class="nav-content w-full">
        <div class="nav-items flex flex-col">
          <router-link class="py-4 w-full" to="/about">About</router-link>
          <router-link class="py-4 w-full" to="/projects">Projects</router-link>
          <span class="py-4 w-full" @click="openResume">Resume</span>
        </div>
      </div>
    </div>

  </div>

  <div
      class="bg-[url('../assets/graphics/header-waves.svg')] w-full h-32 lg:h-48 absolute -z-20 top-0 bg-cover bg-bottom"
      :class="this.scrollPosition < 32 ? 'show' : 'hidden'">
  </div>

  <router-view></router-view>
</template>

<script>
import SvgLogo from "@/components/SvgLogo";
import {
  Menu2Icon,
} from "vue-tabler-icons";

export default {
  components: {
    SvgLogo,
    Menu2Icon
  },
  props: {},
  data() {
    return {
      showMobileMenu: false,
      scrollPosition: 0
    };
  },
  methods: {
    isSmallScreen() {
      return screen.width < 640;
    },
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    openResume() {
      const resume = "./JanessaPerryResume(2023).pdf";
      window.open(resume, "_blank")
    }
  },
  watch: {
    '$route'() {
      this.showMobileMenu = false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scrolled {
  transition: all 0.2s ease-out;
}

@media screen and (max-width: 768px) {
  .closed-menu {
    opacity: 0;
    height: 0;
    padding: 0;
  }

  .closed-menu .nav-items {
    display: none;
  }

  .nav-content {
    /*z-index: 100;*/
    /*transition: all 0.2s ease-out;*/
    /*todo need to fix this*/
  }

}
</style>
