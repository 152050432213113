<template>
  <div id="footer" class="py-16 mt-16 w-full bg-grey-700 ">

    <div class="container">

      <div class="grid md:grid-cols-2 gap-x-4 gap-y-4 items-end">
        <div class="space-y-2">
          <div>
            <p class="font-bold text-white text-2xl">Let's Connect</p>
            <p class="text-white text-xl">I'm currently open to new work opportunities.</p>
            <div class="flex">
              <a href="https://www.linkedin.com/in/janessa-perry/" target="_blank">
                <brand-linkedin-icon class="icon-md icon-stroke-light text-white"/>
              </a>

              <a href="mailto:hello@janessaperry.com">
                <mail-icon class="icon-md icon-stroke-light text-white"/>
              </a>
            </div>
          </div>

          <p class="text-grey-300">© {{ new Date().getFullYear() }} Janessa Perry Design Portfolio</p>
        </div>

        <div class="md:text-right">
          <p class="text-grey-300">Designed and developed by Janessa Perry</p>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {
  BrandLinkedinIcon,
  MailIcon
} from "vue-tabler-icons";

export default {
  components: {
    BrandLinkedinIcon,
    MailIcon
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
