<template>

  <carousel>
    <template #slides>

      <slide v-for="slide in slides" :key="slide">
        <div class="space-y-4 px-8">
          <div class="content-container text-left ">

            <p class="caption">{{ slide.title }}</p>
            <p class="text-lg" v-for="paragraph in slide.paragraphs" :key="paragraph">
              {{ paragraph }}
            </p>

          </div>

          <img :src=slide.image alt="" class="rounded-md shadow-lg shadow-grey-500/5">
        </div>

      </slide>
    </template>

    <template #addons>
      <Navigation/>
      <Pagination/>
    </template>
  </carousel>

</template>


<script>
import {defineComponent} from 'vue'
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'CarouselContainer',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      hooperSettings: {
        infiniteScroll: true,
      }
    }
  },
  props: {
    slides: Array,
  },
});
</script>

<style scoped>

</style>