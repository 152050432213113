<template>
  <div class="about space-y-24 my-40 lg:mt-64">

    <section class="hero intro">
      <div class="container grid grid-cols-6 md:grid-cols-12 gap-8 justify-items-center	items-center">
        <div class="col-span-6 md:col-span-8 xl:col-span-6">
          <div class="row">
            <h1 class="hero">Let me tell you a little more about myself!</h1>
            <p class="text-xl lg:text-2xl text-grey-300 font-thin">So, you already know I'm a designer, but when I'm not working, I'm
              hanging out with my cat, catching a Seahawks game, or playing video games.</p>
          </div>
        </div>

        <div class="col-span-6 md:col-span-4 xl:col-span-6">
          <img alt="" class="max-h-96"
               src="../assets/img/hero/about-collage.png">
        </div>
      </div>
    </section>

    <section class="container motivations space-y-8">
      <div class="section-lead-in space-y-4 text-center">
        <h1>Motivations</h1>
        <p class="text-xl text-grey-300 font-thin">Here are some things that are important to me at work.</p>
      </div>

      <div class="grid gap-4 md:grid-cols-4 md:gap-8">
        <div class="experience-item col-span-4 md:col-span-2 xl:col-span-1 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
          <bulb-icon class="icon-lg icon-stroke-light text-blue-500"/>
          <p class="caption">Learning</p>

          <div class="space-y-1">
            <p>
              As my career path demonstrates, I'm always looking to improve and expand my skill set through learning and practice.
            </p>
          </div>
        </div>

        <div class="experience-item col-span-4 md:col-span-2 xl:col-span-1 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
          <puzzle-icon class="icon-lg icon-stroke-light text-blue-500"/>
          <p class="caption">Problem Solving</p>

          <div class="space-y-1">
            <p>
              There's no better feeling than collaborating with others and reaching that 'aha' moment when solving a new problem.
            </p>
          </div>
        </div>

        <div class="experience-item col-span-4 md:col-span-2 xl:col-span-1 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
          <text-resize-icon class="icon-lg icon-stroke-light text-blue-500"/>
          <p class="caption">Details</p>

          <div class="space-y-1">
            <p>
              Nothing gets me more excited than seeing the small details come together to create something functional and beautiful.
            </p>
          </div>
        </div>

        <div class="experience-item col-span-4 md:col-span-2 xl:col-span-1 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
          <brush-icon class="icon-lg icon-stroke-light text-blue-500"/>
          <p class="caption">Aesthetics</p>

          <div class="space-y-1">
            <p>
              I'm a huge nerd when it comes to colours, fonts, layouts - really just everything design!
            </p>
          </div>
        </div>

      </div>
    </section>

    <section class="container journey space-y-8">
        <div class="section-lead-in space-y-4 text-center">
          <h1>My Journey<span class="text-grey-300">... so far</span></h1>
          <p class="text-xl text-grey-300 font-thin">
            While I'm now working as a designer, I didn't start that way! Check out my career journey below.
          </p>
        </div>


        <div class="journey-timeline">
          <journey-item
              v-for="item in items"
              :key="item.id"
              :company="item.company"
              :description="item.description"
              :location="item.location"
              :period="item.period"
              :title="item.title"
          ></journey-item>
        </div>
    </section>

    <section class="container expertise space-y-8">
      <div class="section-lead-in space-y-4 text-center">
        <h1>Expertise</h1>
        <p class="text-xl text-grey-300 font-thin">
          I'm always looking to hone my skills and learn new tools and technologies.
        </p>
      </div>

      <div class="grid gap-8 md:grid-cols-12">

        <div class="skills md:col-span-6 space-y-4">
          <div class="space-y-2">
            <h2>Skills</h2>
          </div>

          <div class="tags-container flex flex-wrap gap-4">
            <tag-item :class="'primary-tag'">
              <template #tagContent>UX Design</template>
            </tag-item>

            <tag-item :class="'primary-tag'">
              <template #tagContent>UI Design</template>
            </tag-item>

            <tag-item :class="'primary-tag'">
              <template #tagContent>Wireframing</template>
            </tag-item>

            <tag-item :class="'primary-tag'">
              <template #tagContent>Prototyping</template>
            </tag-item>

            <tag-item :class="'primary-tag'">
              <template #tagContent>Responsive Design</template>
            </tag-item>

            <tag-item :class="'primary-tag'">
              <template #tagContent>Style Guides</template>
            </tag-item>

            <tag-item :class="'primary-tag'">
              <template #tagContent>Visual Design & Communication</template>
            </tag-item>

            <tag-item :class="'primary-tag'">
              <template #tagContent>Accessibility</template>
            </tag-item>

            <tag-item :class="'primary-tag'">
              <template #tagContent>HTML & CSS</template>
            </tag-item>

            <tag-item :class="'primary-tag'">
              <template #tagContent>JavaScript</template>
            </tag-item>
          </div>
        </div>

        <div class="tools md:col-span-6 space-y-4">
          <div class="space-y-2">
            <h2>Tools</h2>
          </div>

          <div class="grid gap-4 grid-cols-4 md:gap-8">
            <div class="logo-container">
              <a href="https://www.figma.com/" target="_blank">
                <img alt="Figma Logo" class="h-12 md:h-16 "
                     src="../assets/img/logos/figma-logo.png"/>
              </a>
            </div>

            <div class="logo-container">
              <a href="https://affinity.serif.com/en-gb/designer/" target="_blank">
                <img alt="Affinity Designer Logo" class="h-12 md:h-16 "
                     src="../assets/img/logos/affinity-designer-logo.png"/>
              </a>
            </div>

            <div class="logo-container">
              <a href="https://affinity.serif.com/en-gb/photo/" target="_blank">
                <img alt="Affinity Photo Logo" class="h-12 md:h-16 "
                     src="../assets/img/logos/affinity-photo-logo.png"/>
              </a>
            </div>

            <div class="logo-container">
              <a href="https://affinity.serif.com/en-gb/publisher/" target="_blank">
                <img alt="Afifnity Publisher Logo" class="h-12 md:h-16 "
                     src="../assets/img/logos/affinity-publisher-logo.png"/>
              </a>
            </div>

            <div class="logo-container">
              <a href="https://www.office.com/" target="_blank">
                <img alt="Microsoft Office Logo" class="h-12 md:h-16 "
                     src="../assets/img/logos/ms-office-logo.jpg"/>
              </a>
            </div>

            <div class="logo-container">
              <a href="https://concepts.app/en/" target="_blank">
                <img alt="Concepts App Logo" class="h-12 md:h-16 "
                     src="../assets/img/logos/concepts-logo.png"/>
              </a>
            </div>

            <div class="logo-container">
              <a href="https://wordpress.com/" target="_blank">
                <img alt="WordPress Logo" class="h-12 md:h-16 "
                     src="../assets/img/logos/wordpress-logo.png"/>
              </a>
            </div>

          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
import {
  BulbIcon,
  TextResizeIcon,
  PuzzleIcon,
  BrushIcon,
} from "vue-tabler-icons";
import JourneyItem from "@/components/JourneyItem";
import TagItem from "@/components/TagItem";

export default {
  name: 'AppAbout',
  components: {
    JourneyItem,
    TagItem,
    BulbIcon,
    TextResizeIcon,
    PuzzleIcon,
    BrushIcon,
  },
  data() {
    return {
      items: [
        {
          company: "Self Employed",
          location: "Richmond, BC",
          title: "Freelance Designer",
          period: "2022-Present",
          description: ["After eight long years with Sentis, I made the difficult decision to part ways and start freelancing in an effort to grow as a designer.",
            "Over the past year, I've primarily been doing website redesign and development with a dash of branding and photo editing."],
        },
        {
          company: "The Sentis Group",
          location: "Vancouver, BC",
          title: "Manager, UI Design & Project Solutions",
          period: "2021-2022",
          description: ["Much of my role after this promotion remained the same and my time was continuing to be split between design and development.",
            "I was overseeing the design and development of Sentis' survey platform, training new hires on survey programming, ensuring our documentation was up to date, designing online reporting sites (as well as static research reports), and maintaining Sentis' website and brand assets."],
        },
        {
          company: "The Sentis Group",
          location: "Vancouver, BC",
          title: "UI Design & Project Solutions",
          period: "2017-2021",
          description: ["As I developed my skills in design and front-end development, I was able to take on more responsibility and alleviate some of the strain from the full-stack development team.",
            "I began to oversee the design and development of Sentis' survey platform, designed and maintained Sentis' brand collateral, and was the lead designer for all online reporting sites (interactive KPI dashboards).",
            "In 2020, I had to opportunity to gain experience with WordPress as Sentis decided to move the website over to a CMS. It was great being able to design another website with more knowledge and experience under my belt."],
        },
        {
          company: "The Sentis Group",
          location: "Vancouver, BC",
          title: "Visual Identity & Project Solutions",
          period: "2016-2017",
          description: ["In 2016, Sentis underwent a rebranding - everything was changing from our logo to our website to our brand collateral. I was thrilled to be collaborating with managing partners to create Sentis' new brand identity.",
            "I worked on everything from colour palettes to report templates to business cards. I also began to learn front-end development during this time to build Sentis' new company website and to start assisting the development team with the programming and maintainance of Sentis' online survey platform."],
        },
        {
          company: "The Sentis Group",
          location: "Vancouver, BC",
          title: "Research Manager",
          period: "2015-2016",
          description: ["After learning the basics I was able to take on more responsibility. I began managing my own research projects from project kickoff to the final deliverables.",
            "As I began to work more on my own proposals, reports, and online reporting sites, my eye for design and aptitude for debugging with developers  was noticed."],
        },
        {
          company: "The Sentis Group",
          location: "Vancouver, BC",
          title: "Research Analyst",
          period: "2013-2015",
          description: ["Fresh out of university I moved across the country eager to start a career in the research industry. I landed an entry level role at Sentis and my journey began.",
            "As a research analyst at a small market research company, I served in a support role for Research Managers and Directors by handling day-to-day tasks, such as survey testing, sampling, report proofing, and ensuring all tasks were done on time."],
        },
      ]
    }
  }

}
</script>

<style scoped>

</style>