<template>
  <div class="projects space-y-24 my-40 lg:mt-64">

    <section class="hero intro">
      <div class="container grid grid-cols-6 md:grid-cols-12 gap-8 justify-items-center	items-center">
        <div class="col-span-6 md:col-span-8 xl:col-span-6">
          <div class="row">
            <h1 class="hero">Check out what I've been working on.</h1>
            <p class="text-xl lg:text-2xl font-thin text-grey-300">A portfolio of design work is a constant work in
              progress... here
              is some of mine!</p>
          </div>
        </div>

        <div class="col-span-6 md:col-span-4 xl:col-span-6">
          <img alt="" class="max-h-96"
               src="../assets/img/hero/projects-collage.png">
        </div>
      </div>
    </section>

    <section class="projects container space-y-8">
      <div class="section-lead-in space-y-4 text-center">
        <h1>Projects</h1>
        <p class="text-xl text-grey-300 font-thin">Check out some of my recent projects below.</p>
      </div>

      <div class=" projects-list divide-y divide-grey-50">
        <div class="project-item-container py-16 first:pt-0">
          <router-link v-slot="{navigate}" :to="{name: 'GameStop'}">
            <div class="project-item group" role="link" @click="navigate">
              <div class="grid gap-y-4 md:grid-cols-6 md:gap-x-8">
                <div
                    class="project-image group-hover:transition-transform group-hover:scale-105 group-hover:ease-in-out group-hover:duration-500 md:col-span-2">
                  <img alt="Device Screenshot" class=""
                       src="../assets/img/gamestop/device-mockups-1-600x.png">
                </div>

                <div class="project-info md:col-span-4 space-y-2">
                  <div class="flex space-x-1">
                    <clock-icon class="icon-stroke-light text-grey-300"/>
                    <p class="caption">10 min read</p>
                  </div>

                  <h2 class="group-hover:text-blue-500">GameStop Canada</h2>
                  <p class="">Enhancing GameStop Canada's shopping experience by helping customers find the products
                    they are looking for quickly and easily.</p>

                  <div class="tag-container flex flex-wrap gap-y-2 gap-x-2">
                    <tag-item :class="'primary-tag'">
                      <template #tagContent>UX Design</template>
                    </tag-item>
                    <tag-item :class="'primary-tag'">
                      <template #tagContent>UI Design</template>
                    </tag-item>
                    <tag-item :class="'secondary-tag'">
                      <template #tagContent>E-Commerce</template>
                    </tag-item>
                  </div>

                </div>
              </div>
            </div>
          </router-link>
        </div>

        <div class="project-item-container py-16">
          <router-link v-slot="{navigate}" :to="{name: 'Netolnew'}">
            <div class="project-item group" role="link" @click="navigate">
              <div class="grid md:grid-cols-6 md:gap-x-8">

                <div
                    class="project-image group-hover:transition-transform group-hover:scale-105 group-hover:ease-in-out group-hover:duration-500 md:col-span-2">
                  <img alt="Device Screenshot" class=""
                       src="../assets/img/netolnew/final-designs/device-mockups.png">
                </div>

                <div class="project-info md:col-span-4 space-y-2">
                  <div class="flex space-x-1">
                    <clock-icon class="icon-stroke-light text-grey-300"/>
                    <p class="caption">8 min read</p>
                  </div>
                  <h2 class="group-hover:text-blue-500">NEȾOLṈEW̱</h2>
                  <p class="">Improving the user experience and visual appeal of <svg-netolnew-logo class="inline w-auto h-4"></svg-netolnew-logo>'s website where users can learn about indigenous language revitalization efforts and techniques in Canada.
                  </p>

                  <div class="tag-container flex flex-wrap gap-y-2 gap-x-2">
                    <tag-item :class="'primary-tag'">
                      <template #tagContent>Web Design</template>
                    </tag-item>
                    <tag-item :class="'primary-tag'">
                      <template #tagContent>WordPress</template>
                    </tag-item>
                    <tag-item :class="'secondary-tag'">
                      <template #tagContent>Academic Research</template>
                    </tag-item>
                  </div>
                </div>



              </div>
            </div>
          </router-link>
        </div>

        <div class="py-16 last:pb-0">
          <p class="text-grey-300 font-thin text-xl italic text-center">More to come.</p>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import TagItem from "@/components/TagItem";
import SvgNetolnewLogo from "@/components/SvgNetolnewLogo";
import {
  ClockIcon
} from "vue-tabler-icons";

export default {
  name: 'AppProjects',
  components: {
    TagItem,
    SvgNetolnewLogo,
    ClockIcon,
  }
}
</script>

<style scoped>

</style>