<template>
  <div class="journey-item inline-flex w-full">
    <div class="hidden md:block md:w-1/2 md:px-8">
      <div class="content md:text-right md:pb-16">
          <p class="font-bold">{{company}}</p>
          <p class="text-grey-300">{{location}}</p>
      </div>
    </div>

    <div class="journey-marker hidden md:block flex relative bg-blue-100 w-px">
      <span class="absolute flex rounded-full bg-blue-500 w-4 h-4 -left-2 "></span>
    </div>

    <div class="md:w-1/2 md:px-8">
      <div class="content pb-8 md:pb-16">
        <div class="journey-item-heading inline-flex w-full">
          <div class="w-1/2 md:w-full">
            <p class="font-bold">{{title}}</p>
            <p class="text-grey-300">{{period}}</p>
          </div>

          <div class="md:hidden w-1/2 md:w-full text-right">
            <p class="font-bold">{{company}}</p>
            <p class="text-grey-300">{{location}}</p>
          </div>
        </div>

        <div class="journey-item-description space-y-2">
          <p v-for="item in description" :key="item">{{item}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JourneyItem",
  props: {
    company: String,
    location: String,
    title: String,
    period: String,
    description: Array,
  }
}
</script>

<style scoped>
.journey-item:last-child .journey-marker {
  @apply w-0
}

</style>