import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueHighcharts from 'vue3-highcharts';
import { MotionPlugin } from "@vueuse/motion";


import './styles/app.css';

const Vue = createApp(App);
Vue.use(router);
Vue.use(VueHighcharts);
Vue.use(MotionPlugin, {
    directives: {
        'fadeInUp': {
            initial: {
                opacity: 0,
                y: 100
            },
            enter: {
                opacity: 1,
                y: 100,
                // transition: {
                    // duration: 500,
                // }
            },
            visibleOnce: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 500,
                }
            },
        },
        'popVisibleOnce': {
            initial: {
                opacity: 0,
                y: 0,
                scale: 0,
            },
            enter: {
                opacity: 0,
                y: 0,
                scale: 1,
                transition: {
                duration: 5000,
                    damping: 500
                }
            },
            visibleOnce: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 5000,
                }
            },
        }
    }
});

Vue.mount('#app');

