<template>
  <div class="project-netolnew space-y-32 my-40 lg:mt-64">

    <section class="hero intro">
      <div class="container grid grid-cols-6 md:grid-cols-12 gap-8 justify-items-center	items-center">
        <div class="col-span-6 md:col-span-8 xl:col-span-6 space-y-4">
          <div class="row">
            <p class="caption">Website redesign & development</p>
            <svg-netolnew-logo class="inline w-auto my-2 h-8"></svg-netolnew-logo>
            <p class="text-xl lg:text-2xl font-thin text-grey-300">Improving the user experience and visual appeal of
              <svg-netolnew-logo-thin class="grey-text inline w-auto h-6"></svg-netolnew-logo-thin>'s website where users can learn about indigenous language revitalization efforts and techniques
              in Canada.</p>
          </div>

          <div class="tag-container hidden md:flex flex-wrap gap-y-2 gap-x-2">
            <tag-item :class="'primary-tag'">
              <template #tagContent>Web Design</template>
            </tag-item>
            <tag-item :class="'primary-tag'">
              <template #tagContent>WordPress</template>
            </tag-item>
            <tag-item :class="'secondary-tag'">
              <template #tagContent>Academic Research</template>
            </tag-item>
          </div>
        </div>

        <div class="col-span-6 md:col-span-4 xl:col-span-6">
          <img alt="" class=""
               src="../assets/img/netolnew/final-designs/hero-device-mockups.png">
        </div>
      </div>
    </section>

    <section id="project-overview" ref="project-overview" class="container space-y-16">
      <h1 class="animate-container text-center">Overview</h1>

      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <h2 class="text-blue-600">Background</h2>
          <p class="text-xl">
            <svg-netolnew-logo class="inline w-auto h-5"></svg-netolnew-logo>
            is an Indigenous-led, Indigenous-governed, relationship-based national partnership of scholars,
            community leaders, educators, activists, and allies working at the heart of Indigenous language
            revitalization (ILR) in Canada — formed to engage in positive action through collaborative research.
          </p>

          <p class="text-xl">
            Their website was getting old and difficult to maintain so they decided it was time for an overhaul. That’s
            where I came in.
          </p>
        </div>
      </div>

      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <h2 class="text-blue-600">Objectives</h2>
          <p class="text-xl">
            The main goals for the redesign were to streamline navigation throughout the site, enhance content
            discoverability, and to elevate the overall design aesthetics.
          </p>
        </div>

        <div class="grid gap-4 md:grid-cols-12 md:gap-8">

          <div class="col-span-4 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <hierarchy2-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Objective #1</p>

            <div class="space-y-1">
              <p class="text-lg font-bold">
                Streamline navigation and site organization.
              </p>

              <p class="">
                Over the years my client's website had become confusing to navigate and difficult to maintain.
                Naturally, this was a major focus of the redesign.
              </p>
            </div>
          </div>

          <div class="col-span-4 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <telescope-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Objective #2</p>

            <div class="space-y-1">
              <p class="text-lg font-bold">
                Enhance content discoverability.
              </p>
              <p>
                The primary purpose of the website is to share knowledge and as such, the design needed to
                ensure users were able to find the content they were looking for, as well as discover other sources of information.
              </p>
            </div>
          </div>

          <div class="col-span-4 border border-blue-100 rounded-md shadow-lg shadow-grey-500/5 p-6 space-y-2">
            <brush-icon class="icon-lg icon-stroke-light text-blue-500"/>
            <p class="caption">Objective #3</p>

            <div class="space-y-1">
              <p class="text-lg font-bold">
                Elevate overall design aesthetics.
              </p>
              <p>
                Breathe life into the website with a modern design that is consistent, accessible, and engaging for users to consume information on.
              </p>
            </div>
          </div>

        </div>

      </div>

      <div class="subsection-container space-y-8">
        <div class="subsection-lead-in space-y-4">
          <h2 class="text-blue-600">Final Designs</h2>
        </div>

        <img alt="ADD ALT" class="rounded-md"
             src="../assets/img/netolnew/final-designs/final-design-collage.jpg">
      </div>
    </section>

    <section id="project-process" ref="project-process" class="container space-y-16">
      <h1 class="animate-container text-center">Process</h1>

      <div class="section-lead-in space-y-4">
        <p class="text-xl">
          Throughout the process, I kept my client informed of my thoughts and progress and gathered feedback from them.
          After a couple rounds of feedback to further refine site organization and visual direction, we landed at our
          end result - a website that is much simpler to navigate and find content on compared to the previous
          design.
        </p>
      </div>

      <div class="subsection-container space-y-8">

        <div class="subsection-content space-y-4">
          <h2 class="text-blue-600">Proposed Site Map & Organization</h2>
          <p class="text-xl">
            Before starting my redesign, I needed to get a sense of the content on the site in order to effectively come
            up with a solution for improving navigation and content discoverability.
          </p>

          <p class="">
            Since knowledge sharing is a key aspect of this project, I created a spreadsheet to track all of the
            resources (research reports, videos, external links, etc), where they were currently located on the site,
            and how they were currently presented to users (a page vs a post).
          </p>

          <p class="">
            Next, I worked with my client to create some more useful categories for these resources and where each
            resource should fall under this new system. Using these new categories, we can already see that the site
            will be more simplified and easy to navigate.
          </p>

          <div class="img-container space-y-2">
            <img alt="ADD ALT" class="rounded-md border border-grey-50"
                 src="../assets/img/netolnew/process/sitemap-old-proposed-3.jpg">

            <p class="text-sm text-grey-300 text-center">
              Site map - proposed changes.
            </p>
          </div>
        </div>

        <div class="subsection-content space-y-4">
          <p class="">
            While the proposed site map was an improvement, as we moved further along into the design process, it
            became clear that we could further simplify the experience. By collapsing the Knowledge Sharing and
            Resources into one category to contain all research, documents, videos, etc., users will not have to search as hard to find information.
          </p>

          <div class="img-container space-y-2">
            <img alt="ADD ALT" class="rounded-md border border-grey-50"
                 src="../assets/img/netolnew/process/sitemap-final.jpg">

            <p class="text-sm text-grey-300 text-center">
              Site map - final result.
            </p>
          </div>
        </div>
      </div>


      <div class="subsection-container space-y-8">
        <div class="subsection-content space-y-4">
          <h2 class="text-blue-600">Sketching, Wireframing, & Prototyping</h2>
          <p class="text-xl">
            After nailing down the site hierarchy and pages that needed to be designed, I jumped into sketching and
            wireframing while ensuring the proposed changes flowed intuitively for users through protoyping.
          </p>
          <p>
            During this stage, I really wanted to focus on the flow of the site and how easily users are able to find
            what they're looking for, specifically in the Knowledge Sharing portion of the site. In the first iterations
            of the design, we planned to keep Knowledge Sharing and Resources separate. After putting together some
            wireframes and a basic prototype, we quickly realized we needed to further collapse and refine the categories.
          </p>

          <div class="img-container space-y-2">
            <img alt="ADD ALT" class="rounded-md border border-grey-50"
                 src="../assets/img/netolnew/process/wireframes-collage2.jpg">

            <p class="text-sm text-grey-300 text-center">
              Initial wireframe designs.
            </p>
          </div>
        </div>
      </div>

      <div class="subsection-container space-y-8">
        <div class="subsection-content space-y-4">
          <h2 class="text-blue-600">Visual Design</h2>
          <p class="text-xl">
            With the pages, content, and layout all sorted, it was time to dive into the visual design.
          </p>
          <p>
            One of the challenges I faced with this site was where to take the visual direction. I explored some options
            featuring imagery such as landscapes and nature, as well as an option with graphics and artwork created by
            Indigenous artists. In the end, we used a mix of artwork and elements of the logo throughout the site.
          </p>

          <p>
            Throughout this exploratory process, I created a library of styles and interactive components to ensure everything was consistent throughout as that was an issue with the previous site.
          </p>

          <div class="img-container space-y-2">
            <img alt="ADD ALT" class="rounded-md border border-grey-50"
                 src="../assets/img/netolnew/process/visual-design-collage.jpg">

            <p class="text-sm text-grey-300 text-center">
              Visual explorations.
            </p>
          </div>
        </div>
      </div>
    </section>


    <section id="project-before-after" ref="project-before-after" class="container space-y-16">
      <h1 class="animate-container text-center">Before & After</h1>

      <div class="subsection-container space-y-8">
        <div class="subsection-content space-y-4">
          <h2 class="text-blue-600">Home Page</h2>
          <p class="text-xl">
            Starting off with the home page, I wanted to make it clear what <svg-netolnew-logo class="inline w-auto h-5"></svg-netolnew-logo> does right away as well as bring
            in some Indigenous elements. In the brainstorming phase, I thought it would be neat to show users some facts
            and figures on Indigenous languages and how many speakers there currently are in Canada, however we wanted
            to ensure the site was easy to update and maintain so this idea was scrapped. In the end, we decided to display
            some Indigenous greetings as well as a pronunciation guide for some of the common words used throughout the site.
          </p>

          <div class="grid grid-cols-12">
            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">Before</p>
              <ul class="">
                <li>Users have to scroll to the bottom of the page to learn what the site is about.</li>
                <li>Footer does not contain much information, it is difficult to see the icons, and the formatting is off.</li>
                <li>Issues with spacing, font rendering, and readability.</li>
                <li>Does not display well on mobile.</li>
              </ul>
            </div>

            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">After</p>
              <ul>
                <li>Users know what <svg-netolnew-logo class="inline w-auto h-4"></svg-netolnew-logo> is all about right away.</li>
                <li>Brought in some Indigenous words and phrases.</li>
                <li>Footer contains more information, including address and contact information which adds credibility
                  to the site.
                </li>
                <li>Tidied up spacing, font rendering, and readability issues.</li>
                <li>Design is fully responsive.</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="img-container space-y-2">
          <img alt="ADD ALT" class="rounded-md"
               src="../assets/img/netolnew/final-designs/home-page-before-after.jpg">

          <p class="text-sm text-grey-300 text-center italic">
            Home page - before and after.
          </p>
        </div>
      </div>


      <div class="subsection-container space-y-8">
        <div class="subsection-content space-y-4">
          <h2 class="text-blue-600">Our Project</h2>
          <p class="text-xl">
            The project page was previously on four different pages and I really wanted to simplify this area so users
            were not having to click around to learn about different aspects of the project.
          </p>

          <div class="grid grid-cols-12">
            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">Before</p>
              <ul class="">
                <li>Users have to access four different pages to learn about the project.</li>
                <li>Lots of paragraphs for users to read.</li>
                <li>Issues with spacing, font rendering, and readability.</li>
              </ul>
            </div>

            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">After</p>
              <ul>
                <li>Combined information into one page.</li>
                <li>Broke the content down into smaller pieces and displayed them in simpler terms so users are not
                  reading long paragraphs.
                </li>
                <li>Added an accordion for some interactivity as users learn about the project.</li>
                <li>Updated the house graphic to use brand colours.</li>
                <li>Moved the Project Guides to the Knowledge Sharing section to be housed with all other resources on
                  the site.
                </li>
                <li>Tidied up spacing, font rendering, and readability issues.</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="img-container space-y-2">
          <img alt="ADD ALT" class="rounded-md"
               src="../assets/img/netolnew/final-designs/our-project-before-after.jpg">

          <p class="text-sm text-grey-300 text-center italic">
            Our Project page - before and after.
          </p>
        </div>
      </div>


      <div class="subsection-container space-y-8">
        <div class="subsection-content space-y-4">
          <h2 class="text-blue-600">Partners</h2>
          <p class="text-xl">
            While the content on the Partners page remained largely the same, I wanted to enhance the visual appeal by adding some images, improving the quality of the map graphic, and bringing in logos for each of
            the nine partners.
          </p>

          <div class="grid grid-cols-12">
            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">Before</p>
              <ul class="">
                <li>Lacks visual appeal.</li>
                <li>Map is small, pixelated, and difficult to read.</li>
                <li>The table shows the same information as the map (though it includes links to the Partner
                  websites).
                </li>
              </ul>
            </div>

            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">After</p>
              <ul>
                <li>Added a photo of the Partnership to add an interpersonal touch.</li>
                <li>Updated the graphic to use brand colours and to improve clarity and readability.</li>
                <li>Added Partner logos for visual appeal. Logos also link to the Partners own websites.</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="img-container space-y-2">
          <img alt="ADD ALT" class="rounded-md"
               src="../assets/img/netolnew/final-designs/partners-before-after.jpg">

          <p class="text-sm text-grey-300 text-center italic">
            Partners page - before and after.
          </p>
        </div>
      </div>


      <div class="subsection-container space-y-8">
        <div class="subsection-content space-y-4">
          <h2 class="text-blue-600">People</h2>
          <p class="text-xl">
            The People page was another one that I wanted to both simplify and add some visual appeal to. On the old
            site, users would first see three tabs. After clicking a tab, they would then see a brief description of the
            category and a link to direct them to that category. This process was both cumbersome and confusing to
            users.
          </p>

          <div class="grid grid-cols-12">
            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">Before</p>
              <ul class="">
                <li>It takes several clicks and decisions to learn about team members.</li>
                <li>Images of people are not consistent sizes.</li>
                <li>Biographies are long and all shown on load which can overwhelm users.</li>
                <li>Difficult to read accordion titles (not enough contrast).</li>
                <li>Issues with spacing, font rendering, and readability.</li>
                <li>Footer not anchored at bottom of page.</li>
              </ul>
            </div>

            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">After</p>
              <ul>
                <li>Simplified navigation by showing all team members on a single page.</li>
                <li>Showing biographies for each user in modals to engage users while reducing cognitive load.</li>
                <li>Tidied up spacing, font rendering, and readability issues.</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="img-container space-y-2">
          <img alt="ADD ALT" class="rounded-md"
               src="../assets/img/netolnew/final-designs/people-before-after.jpg">

          <p class="text-sm text-grey-300 text-center italic">
            People page - before and after.
          </p>
        </div>
      </div>


      <div class="subsection-container space-y-8">
        <div class="subsection-content space-y-4">
          <h2 class="text-blue-600">Knowledge Sharing & Resources</h2>
          <p class="text-xl">
            Initially, the plan was to keep these two as separate categories, however, after seeing the design
            prototype, we decided to collapse the two and further combine the subcategories. The end result is much
            smoother in terms of navigation and allows users to more easily discover the resources on the site.
          </p>

          <div class="grid grid-cols-12">
            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">Before</p>
              <ul class="">
                <li>Cumbersome to find specific categories and materials because there are so many.</li>
                <li>Lacks visual interest and makes the user click too many times to find what they are looking for
                  (first, to learn what each category is about, and again to be directed to that page).
                </li>
                <li>Issues with spacing, font rendering, and readability.</li>
                <li>Footer not anchored at bottom of page.</li>
              </ul>
            </div>

            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">After</p>
              <ul>
                <li>Simplified navigation by further collapsing Knowledge Sharing and Resources into one category.</li>
                <li>Showing users a description for each category on load so they are not having to click so many
                  times.
                </li>
                <li>Tidied up spacing, font rendering, and readability issues.</li>
                <li>Fixed footer issue and anchored it to the bottom of the page.</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="img-container space-y-2">
          <img alt="ADD ALT" class="rounded-md"
               src="../assets/img/netolnew/final-designs/knowledge-sharing-before-after.jpg">

          <p class="text-sm text-grey-300 text-center italic">
            Knowledge Sharing & Resources pages - before and after.
          </p>
        </div>
      </div>


      <div class="subsection-container space-y-8">
        <div class="subsection-content space-y-4">
          <h2 class="text-blue-600">Knowledge Sharing & Resources Sub Pages</h2>
          <p class="text-xl">
            Diving further into these pages, after selecting a category, users would see either see a page with some
            articles, a collection of shared articles / media, or another navigation page to further dig in to the
            content. The goal here was to simplify these pages as much as possible while bringing consistency to the
            design not only to help the user navigate the site, but to help with further updates and posts being added
            in the future.
          </p>

          <div class="grid grid-cols-12">
            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">Before</p>
              <ul class="">
                <li>Content is not easily discovered - users have to click around quite a bit to find what they might be
                  looking for.
                </li>
                <li>Content is not displayed in a consistent way making it difficult to maintain and update the site
                  moving forward.
                </li>
                <li>The Resource page for ‘Indigenous Languages in the Media’ is not clear that it is simply a Twitter
                  feed.
                </li>
                <li>Accessibility and consistency issues with tabs and buttons.</li>
                <li>Issues with spacing, font rendering, and readability.</li>
              </ul>
            </div>

            <div class="column col-span-12 md:col-span-6">
              <p class="text-lg font-bold">After</p>
              <ul>
                <li>Simplified navigation by further collapsing Knowledge Sharing and Resources into one category.</li>
                <li>Added tags for Topics to provide users with more ways of finding the content they are looking for.
                </li>
                <li>Provided a Directory to allow users to quickly check out information in different areas.</li>
                <li>Created layouts for different styles of content (videos, articles, etc.), to help orient users to
                  the content and create a familiar experience while browsing the site.
                </li>
                <li>Brought in a small Twitter feed on the sidebar to share updates from <svg-netolnew-logo class="inline w-auto h-4"></svg-netolnew-logo> rather than a whole
                  page dedicated to it.
                </li>
                <li>Tidied up spacing, font rendering, and readability issues.</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="img-container space-y-2">
          <img alt="ADD ALT" class="rounded-md"
               src="../assets/img/netolnew/final-designs/knowledge-sharing-posts-before-after.jpg">

          <p class="text-sm text-grey-300 text-center italic">
            Knowledge Sharing sub pages - before and after.
          </p>
        </div>

        <div class="img-container space-y-2">
          <img alt="ADD ALT" class="rounded-md"
               src="../assets/img/netolnew/final-designs/resources-posts-before-after.jpg">

          <p class="text-sm text-grey-300 text-center italic">
            Resources sub pages - before and after.
          </p>
        </div>
      </div>

    </section>


    <section id="project-conclusion" ref="project-process" class="container space-y-16">
      <h1 class="animate-container text-center">In conclusion</h1>

      <div class="section-lead-in space-y-4">
        <p class="text-xl">
          Working on this site was a blast. I had so much fun helping <svg-netolnew-logo class="inline w-auto h-5"></svg-netolnew-logo> breathe life into their website to help
          users learn about indigenous language revitalization efforts across Canada.
        </p>

      </div>
    </section>


  </div>
</template>

<script>
import TagItem from "@/components/TagItem";
import SvgNetolnewLogo from "@/components/SvgNetolnewLogo";
import SvgNetolnewLogoThin from "@/components/SvgNetolnewLogoThin";
import {
  Hierarchy2Icon,
  TelescopeIcon,
  BrushIcon,
} from "vue-tabler-icons";

export default {
  name: "ProjectNetolnew",
  components: {
    SvgNetolnewLogo,
    SvgNetolnewLogoThin,
    Hierarchy2Icon,
    TelescopeIcon,
    BrushIcon,
    TagItem,
  },
}
</script>

<style scoped>

</style>